exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-sectors-commercial-jsx": () => import("./../../../src/pages/sectors/commercial.jsx" /* webpackChunkName: "component---src-pages-sectors-commercial-jsx" */),
  "component---src-pages-sectors-educational-jsx": () => import("./../../../src/pages/sectors/educational.jsx" /* webpackChunkName: "component---src-pages-sectors-educational-jsx" */),
  "component---src-pages-sectors-healthcare-jsx": () => import("./../../../src/pages/sectors/healthcare.jsx" /* webpackChunkName: "component---src-pages-sectors-healthcare-jsx" */),
  "component---src-pages-sectors-jsx": () => import("./../../../src/pages/sectors.jsx" /* webpackChunkName: "component---src-pages-sectors-jsx" */),
  "component---src-pages-sectors-residential-jsx": () => import("./../../../src/pages/sectors/residential.jsx" /* webpackChunkName: "component---src-pages-sectors-residential-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

